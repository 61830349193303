<template>
  <section>
    <van-nav-bar title="合同列表" left-arrow @click-left="backSpace" />
    <div v-for="(item, index) in dataList" :key="index" class="module">
      <div class="module_item">项目名称：{{ item.name }}</div>
      <div class="module_item">甲方单位：{{ item.company }}</div>
      <div class="module_item">金额：{{ item.price }}</div>
      <div class="module_item">发票抬头：{{ item.code }}</div>
      <div class="module_item">税号：{{ item.num }}</div>
      <div class="module_item">项目内容：{{ item.content }}</div>
      <div class="module_item">收货人：{{ item.receiver_name }}</div>
      <div class="module_item">电话：{{ item.receiver_phone }}</div>
      <div class="module_item">收货地址：{{ item.receiver_address }}</div>
    </div>
    <van-dialog v-model="alert" title="图片详情" show-cancel-button>
      <img class="alert_pic" :src="image" />
    </van-dialog>
  </section>
</template>
<script>
import Vue from "vue";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
import { contractList } from "@/assets/api/api";
import { Dialog } from "vant";
// 全局注册
Vue.use(Dialog);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      dataList: "",
      alert: false,
      image: "",
    };
  },
  computed: {},
  methods: {
    imageClick(e) {
      this.alert = true;
      this.image = e;
    },
    contractList() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      contractList().then((res) => {
        Toast.clear();
        if (res.data.code == 0) {
          this.dataList = res.data.data.data;
        } else {
          Toast(res.data.message);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.contractList();
  },
};
</script>
<style scoped>
.module {
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
}
.module_item {
  padding: 5px 0;
  font-size: 14px;
  color: #333;
}
.module_pic {
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 0 5px 5px;
}
.list {
  padding: 10px 0;
  font-size: 14px;
}
.list_item{
  margin-top: 15px;
}
.alert_pic {
  display: block;
  width: 100%;
}
</style>
